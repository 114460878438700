import React, { createContext, useContext, useEffect, useState } from "react";
import msalInstance from "./msalInstance";
import { loginRequest } from "./authConfig";
import { EventType } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [account, setAccount] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();

    const initializeMsal = async () => {
        try {
            await msalInstance.initialize();
            setIsInitialized(true);

            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                setAccount(accounts[0]); // 이미 로그인된 사용자 정보를 설정
                return;
            }

            const response = await msalInstance.handleRedirectPromise();
            if (response !== null && response.account) {
                const { account } = response;
                setAccount(account);
                navigate("/dashboard");
            } else {
                console.error("인증 정보가 없습니다.");
                await msalInstance.loginRedirect(loginRequest);
            }
        } catch (error) {
            console.error("MSAL 초기화 실패:", error);
        }
    };

    useEffect(() => {
        initializeMsal();
    }, []);


    const login = async () => {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            setAccount(accounts[0]); // 이미 로그인된 사용자 정보를 설정
            return;
        }

        if (isInitialized && !isLoggingIn) {
            try {
                setIsLoggingIn(true);
                msalInstance.loginRedirect(loginRequest);
            } catch (err) {
                console.error(err);
            }
        }
    };

    const logout = () => {
        msalInstance.logoutRedirect();
        setAccount(null);
    };

    return (
        <AuthContext.Provider
            value={{
                initializeMsal,
                isInitialized,
                account,
                setIsInitialized,
                setAccount,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
