import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from "chart.js";
import { Line, Pie, Bar, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from 'styled-components';


const ErrorList = styled.div`
    margin-bottom: 5px;
    
    p {
        font-weight: 700;
        margin-bottom: 0;
    }
`;
const ErrorTtile = styled.strong`
    font-size: 35px;
    font-weight: 700;
`;

const ErrorSubTitle = styled.p`
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
`;

// Chart.js에 구성 요소 등록
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
	ChartDataLabels
);

const getDummyData = () => {
	// Dummy data for the example
	return {
		labels: [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
			"Sunday",
		],
		datasets: [
			{
				label: "Project A",
				data: [12, 19, 3, 5, 2, 3, 7],
				borderColor: "#FF6384",
				backgroundColor: "rgba(255, 99, 132, 0.2)",
				fill: true,
			},
			{
				label: "Project B",
				data: [10, 15, 5, 2, 20, 3, 10],
				borderColor: "#36A2EB",
				backgroundColor: "rgba(54, 162, 235, 0.2)",
				fill: true,
			},
			{
				label: "Project C",
				data: [5, 10, 15, 7, 8, 12, 5],
				borderColor: "#FFCE56",
				backgroundColor: "rgba(255, 206, 86, 0.2)",
				fill: true,
			},
		],
	};
};

const ErrorByDayChart = () => {
	const data = getDummyData();

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: true,
				text: "Errors by Day for Top 3 Projects",
			},
		},
	};

	return <Line data={data} options={options} />;
};

// 커스텀 플러그인 설정
const totalPlugin = {
	id: "totalPlugin",
	beforeDraw: (chart) => {
		const { ctx, data } = chart;
		ctx.save();
		const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
		ctx.font = "bold 20px Arial";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";
		ctx.fillStyle = "#000";
		const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
		const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
		ctx.fillText(`Total: ${total}`, centerX, centerY);
		ctx.restore();
	},
};

const getErrorCodeData = () => {
	// Dummy data for the example
	return {
		labels: [
			"Error 404",
			"Error 500",
			"Error 502",
			"Error 400",
		],
		datasets: [
			{
				label: "Error Codes",
				data: [25, 20, 10, 5],
				backgroundColor: [
					"#FF6384",
					"#36A2EB",
					"#4BC0C0",
					"#9966FF",
				],
				hoverBackgroundColor: [
					"#FF6384",
					"#36A2EB",
					"#4BC0C0",
					"#9966FF",
				],
			},
		],
	};
};

const ErrorCodeDonutChart = () => {
	const data = getErrorCodeData();

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: true,
				text: "Top 5 Error Codes",
			},
		},
	};

	return <Doughnut data={data} options={options} plugins={[totalPlugin]} />;
};

const Dashboard = () => {
	return (
		<div className="dashboard">
			<h1>Dashboard</h1>
            <Row className="row-stats mt-4">
                <Col sm={12} md={6} lg={4} className="mb-4">
                    <Card className="error-recent"> 
                        <i className="bi bi-card-list"></i>
                        <ErrorList>
                            <p>프로젝트 이름</p>
                            <p>에러 내용</p>
                        </ErrorList>
                        <ErrorSubTitle>Recnt errors (최근 에러 발생 프로젝트 3)</ErrorSubTitle>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} className="mb-4">
                    <Card className="error-total">
                        <i className="bi bi-exclamation-triangle-fill"></i>
                        <ErrorTtile>105</ErrorTtile>
                        <ErrorSubTitle>Errors (주간 에러 개수)</ErrorSubTitle>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4} className="mb-4">
                    <Card className=" error-ratio">
                        <i className="bi bi-graph-up-arrow"></i>
                        <ErrorTtile>+ 20%</ErrorTtile>
                        <ErrorSubTitle>Error ratio (지난주 대비 에러 증감비)</ErrorSubTitle>
                    </Card>
                </Col>
            </Row>
			<Row className="mt-4">
				<Col sm={12} md={6} lg={8} className="mb-4">
                    <Card>
                        <ErrorByDayChart />
                    </Card>
				</Col>
                <Col sm={12} md={6} lg={4} className="mb-4">
                    <Card>
                        <ErrorCodeDonutChart />
                    </Card>
				</Col>
			</Row>
		</div>
	);
};

export default Dashboard;
