import React, { useEffect } from 'react'
import { useAuth } from "../../auth/AuthProvider";
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const { isInitialized, account, login, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(account) return navigate('/dashboard');
        if(!account && isInitialized) login();
    }, [])

    return (
        <div></div>
    )
}

export default Login