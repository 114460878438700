import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';
import moment from 'moment';

const ErrorTitle = styled.span`
    font-weight: 700;
    color: #984141;
`

const ErrorTime = styled.span`
    float: right;
`

const ProjectDetail = () => {
	const { projectId } = useParams();
	const [project, setProject] = useState(null);
	const [errors, setErrors] = useState([]);
	const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const fetchProject = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/projects/detail/${projectId}?start_date=${startDate}&end_date=${endDate}`
            );
            const { info, errors } = response.data.data;

            setProject(info);
            setErrors(errors);
        } catch (error) {
            console.error(
                "프로젝트를 가져오는 중 문제가 발생했습니다.",
                error
            );
        }
    };
	useEffect(() => {
		fetchProject();
	}, [projectId]);

	return (
		<Container className="project-detail">
			{project && (
				<>
					<h1>{project.name}</h1>
					<h3>ID: {project.id}</h3>
					<Form className="detail-date mt-4">
                        <Form.Group controlId="startDate">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control"
                            />
                        </Form.Group>
                        <div>~</div>
                        <Form.Group controlId="endDate">
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                className="form-control"
                            />
                        </Form.Group>
                        <Button variant="dark" className="mt-3" onClick={() => fetchProject()}>
                            조회
                        </Button>
					</Form>
					<Row className="mt-4">
						{errors.length > 0 ? errors?.map((error) => (
							<Col key={error.id} sm={12} md={6} lg={12} className="mb-4">
								<Card>
									<Card.Header>
                                            <ErrorTitle>
                                                [ {error.env} ] {error.err_msg}
                                            </ErrorTitle>
										<ErrorTime>
											{new Date(error.register_time).toLocaleString()}
										</ErrorTime>
									</Card.Header>
									<Card.Body>
                                        <Card.Text>
											Status Code: {error.status_code}
										</Card.Text>
										<Card.Text>
											Error Type: {error.err_type}
										</Card.Text>
										<Card.Text>
                                            Error Code: {error.err_code}
										</Card.Text>
										<Card.Text>
                                        Error Stack:
										</Card.Text>
                                        <Card.Text>
                                        {
                                            (error.err_file && error.err_line) ? `${error.err_file} (Line: ${error.err_line})` : error.err_stack
                                        }
                                        </Card.Text>
									</Card.Body>
								</Card>
							</Col>
						)) : <Col sm={12} md={6} lg={12} className="mb-4">발생한 에러가 없습니다.</Col>}
					</Row>
				</>
			)}
		</Container>
	);
};

export default ProjectDetail;
