import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';

const AddProjectForm = ({ isOpen, onClose, onAddProject }) => {
  const { register, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm();

  useEffect(() => {
    if (!watch('isAlert')) {
      setValue('channels', '');
    }
  }, [watch('isAlert'), setValue]);


  const handleHide = () => {
    reset();
    onClose();
  }

  const onSubmit = (data) => {
    onAddProject(data);
    reset();
  };

  return (
    <Modal show={isOpen} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>프로젝트 등록</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>프로젝트 이름</Form.Label>
            <Form.Control
              type="text"
              placeholder="프로젝트 이름"
              {...register('name', { required: true, maxLength: 30 })}
              isInvalid={errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.type === 'required' && "프로젝트 이름을 입력하세요."}
              {errors.name?.type === 'maxLength' && "프로젝트 이름은 최대 30자까지 입력할 수 있습니다."}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>프로젝트 설명</Form.Label>
            <Form.Control
              type="text"
              placeholder="프로젝트 설명"
              {...register('description')}
              isInvalid={errors.description}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check
              type="checkbox"
              label="알림 설정"
              {...register('isAlert')}
            />
          </Form.Group>
          {watch('isAlert') && (
            <div>
                <Form.Group>
                  <Form.Label>알림 받을 채널 (쉼표로 구분)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="채널 이름"
                    {...register(`channels`, { required: true })}
                    isInvalid={errors.channels}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.channels && "알림받을 채널 ID를 입력하세요."}
                  </Form.Control.Feedback>
                </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleHide}>취소</Button>
          <Button variant="dark" type="submit">등록</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddProjectForm;
