import ScrollToTop from "./components/atoms/ScrollToTop";
import React, { useEffect } from "react";
import PageTemplate from "./components/templates/PageTemplate";
import Dashboard from "./components/pages/Dashboard";
import Monit from "./components/pages/Monit";
import ProjectDetail from "./components/pages/ProjectDetail";
import { useAuth } from "./auth/AuthProvider";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/pages/Login";
import AuthComplete from "./components/pages/AuthComplete";

const App = () => {
    const { account, login, logout } = useAuth();
    const navigate = useNavigate();

	return (
		<div className="App">
			<ScrollToTop />
			<Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/authComplete" element={<AuthComplete />} />
                <Route element={<PageTemplate />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/monit" element={<Monit />} />
                    <Route
                        path="/projects/:projectId"
                        element={<ProjectDetail />}
                    />
                    <Route path="/" element={<Dashboard />} exact />
                </Route>
			</Routes>
		</div>
	);
};

export default App;
