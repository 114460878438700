import { useAuth } from "../../auth/AuthProvider";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import NavSideBar from "../organisms/NavSideBar";

const colorArr = ['#e6a46e', '#e6da6e', '#cee66e', '#98cbd9', '#ac98d9'];
const bgColor = Math.floor(Math.random() * 5);

const Wrap = styled.div`
    display: flex;
    min-width: 1200px;

    @media only screen and (max-width: 1200px) {
        min-width: auto;
    }
`;
const BtnContainer = styled.div`
    display: flex;
    gap: 0 10px;
    justify-content: flex-end;

    button,
    a {
        min-width: 100px;
    }
`;

const Page = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: calc(100% - 250px);

    > .Loading {
        position: absolute;

        > div {
            height: 100vh;
        }
    }
`;

const PageHeader = styled.header`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding: 0 50px;
    height: 60px;
    border-bottom: 1px solid rgba(231, 234, 243, 0.7);
    z-index: 99;
    background-color: #fff;
`;

const PageBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 50px;
    flex: 1 1;
    gap: 1rem 0;

    &.detail {
        justify-content: space-between;
        margin-top: 30px;
    }

    .page-title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    @media only screen and (max-width: 1200px) {
        padding: 10px 20px;
    }
`;

const Profile = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    background: ${colorArr[bgColor]};
    cursor: pointer;

    span {
        flex: 1;
        text-align: center;
        font-size: 1.1rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;        
    }
`

const PageTemplate = ({...props}) => {
    const { account, login, logout } = useAuth();

    return (
        <Wrap {...props}>
            <NavSideBar />

            <Page>
                <PageHeader>
                    <Profile onClick={logout}><span>{account?.name.slice(0, 1)}</span></Profile>
                </PageHeader>

                <PageBody>
                    <Outlet/>
                </PageBody>
            </Page>
        </Wrap>
    );
}


function Content({children, ...props}) {
    return (
        <div className="content-wrap" {...props}>
            {children}
        </div>
    );
}

PageTemplate.Content = Content;
export default PageTemplate;