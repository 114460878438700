export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MS_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MS_TENANT}`,
      redirectUri: process.env.REACT_APP_MS_REDIRECT_URL
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
  };
  
export const loginRequest = {
    scopes: ["User.Read"]
};