import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import styled from "styled-components";
import AddProjectForm from "../organisms/AddProjectForm";
import EditProjectForm from "../organisms/EditProjectForm";

const TileLine = styled.div`
	margin-bottom: 1.5rem;
`

const Monit = () => {
	const [projects, setProjects] = useState([]);
	const [isAddProjectFormOpen, setAddProjectFormOpen] = useState(false);
	const [editProject, setEditProject] = useState(null); // 수정할 프로젝트 정보 저장
	const [isEditModalOpen, setEditModalOpen] = useState(false); // 수정 모달 열림 상태
    const [projectInfo, setProjectInfo] = useState({});
    let [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

	const fetchProjects = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/projects/list`
			);

			setProjects(response.data.data);
		} catch (error) {
			console.error(
				"프로젝트를 가져오는 중 문제가 발생했습니다.",
				error
			);
		}
	};


	useEffect(() => {
		fetchProjects();
	}, []);

    // 프로젝트 추가
	const handleAddProject = async(newProject) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/projects/`, 
				newProject
			);

			if(response.status === 201) {
                const result = response.data.data;
                const projectInfo = { id: result.id, token: result.token };

                setProjectInfo(projectInfo);
                setIsOpen(true);
                fetchProjects();
            }
		} catch (error) {
			console.error(
				"프로젝트를 가져오는 중 문제가 발생했습니다.",
				error
			);
		}

		setAddProjectFormOpen(false);
	};

    const handleCloseModal = () => {
        setIsOpen(false);
        setProjectInfo({});
    }

	const handleCardClick = (projectId) => {
		navigate(`/projects/${projectId}`);
	};

	const handleEditClick = (project) => {
		setEditProject(project);
		setEditModalOpen(true);
	};

	const handleEditModalClose = () => {
		setEditModalOpen(false);
		setEditProject(null);
	};

    // 프로젝트 수정
	const handleEditProject = async (updatedProject) => {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_API_URL}/projects/${updatedProject.id}`,
				updatedProject
			);

			if (response.status === 204) {
				fetchProjects();
				handleEditModalClose();
			}
		} catch (error) {
			console.error(
				"프로젝트를 업데이트하는 중 문제가 발생했습니다.",
				error
			);
		}
	};

	return (
        <>
            <Container>
                <Button variant="dark" onClick={() => setAddProjectFormOpen(true)}>
                    프로젝트 추가
                </Button>
                <Row className="mt-4">
                    {projects?.map((project) => (
                        <Col
                            key={project.id}
                            sm={12}
                            md={6}
                            lg={4}
                            className="mb-4"
                        >
                            <Card>
                                <Card.Body>
                                    <TileLine onClick={() => handleCardClick(project.id)}>
                                        <Card.Title>{project.name}</Card.Title>
                                        <Card.Text>{project.description}</Card.Text>
                                    </TileLine>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {project.is_alert ? (
                                            <i className="bi bi-bell-fill"></i>
                                        ) : (
                                            <i className="bi bi-bell-slash"></i>
                                        )}
                                        <i className="bi bi-gear" onClick={() => handleEditClick(project)} style={{ cursor: 'pointer' }}></i>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <AddProjectForm
                    isOpen={isAddProjectFormOpen}
                    onClose={() => setAddProjectFormOpen(false)}
                    onAddProject={handleAddProject}
                />
                <EditProjectForm
                    project={editProject}
                    isOpen={isEditModalOpen}
                    onClose={handleEditModalClose}
                    onEditProject={handleEditProject}
                />
            </Container>

            <Modal show={isOpen}>
                <Modal.Header>
                    <Modal.Title>프로젝트 정보</Modal.Title>
                </Modal.Header>
                
                <Modal.Body style={{ overflowWrap: 'break-word' }}>
                    ID: {projectInfo?.id} <br></br>
                    Token: {projectInfo?.token}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" type="button" onClick={handleCloseModal}>확인</Button>
                </Modal.Footer>
            </Modal>
        </>
	);
};

export default Monit;
