import React, { useEffect } from "react";
import msalInstance from "../../auth/msalInstance";
import { loginRequest } from "../../auth/authConfig";
import { useAuth } from "../../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

const AuthComplete = () => {
    const {
        initializeMsal,
        isInitialized,
        account,
        setIsInitialized,
        setAccount,
        login,
        logout,
    } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        initializeMsal();
    }, []);

    return <></>;
};

export default AuthComplete;
